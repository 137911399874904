import request from '@/utils/request'

// 查询优惠策略列表
export function listDiscountsStrategy(query) {
  return request({
    url: '/business/discountsStrategy/list',
    method: 'get',
    params: query
  })
}

// 查询优惠策略详细
export function getDiscountsStrategy(id) {
  return request({
    url: '/business/discountsStrategy/' + id,
    method: 'get'
  })
}

// 新增优惠策略
export function addDiscountsStrategy(data) {
  return request({
    url: '/business/discountsStrategy',
    method: 'post',
    data: data
  })
}

// 修改优惠策略
export function updateDiscountsStrategy(data) {
  return request({
    url: '/business/discountsStrategy',
    method: 'put',
    data: data
  })
}

// 删除优惠策略
export function delDiscountsStrategy(id) {
  return request({
    url: '/business/discountsStrategy/' + id,
    method: 'delete'
  })
}

// 获取所属模块
export function getModulOption(modulType, name) {
  return request({
    // url: '/business/discountsStrategy/getModulOption?modulType=' + modulType + '&mudulName='+ name,
    url: '/business/discountsStrategy/getModulOption',
    method: 'get',
    params: {
      modulType,
    }
  })
}


export function getAllRecharge() {
  return request({
    url: '/business/discountsStrategy/getAllRecharge',
    method: 'get'
  })
}
