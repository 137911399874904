<script setup>
import { listServiceProject } from "@/api/business/serviceProject";
import { listBase as storeListBase } from "@/api/store/base";
import {
  addDiscountsStrategy,
  updateDiscountsStrategy
} from "@/api/business/discountsStrategy";

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  form: {
    type: Object,
    default: {}
  },
  rules: {
    type: Object,
    default: {}
  }
});
const title = computed(() => {
    return props.title;
  }
);
const form = computed(() => {
    console.log(props.form);
    return props.form;
  }
);
const rules = computed(() => {
    return props.rules;
  }
);
const { proxy } = getCurrentInstance();
const serviceProjectList = ref([]);
const storeOptions = ref([]);
const buttonLoading = ref(false);
const emit = defineEmits(["refresh", "refList", "update:modelValue"]);

function getServiceProjectList() {
  listServiceProject({ status: 1 }).then(res => {
    serviceProjectList.value = res.rows;
    serviceProjectList.value.unshift({ id: "0", serviceName: "不限服务项目" });
  });
}

function getStore() {
  storeListBase().then((response) => {
    storeOptions.value = response.rows;
    storeOptions.value.unshift({ storeId: "0", storeName: "不限门店" });
  });
}

function changeModelValue(value){
  emit("update:modelValue", value)
}

/** 提交按钮 */
function submitForm(number) {
  proxy.$refs["discountsStrategyRef"].validate(valid => {
    if (valid) {
      buttonLoading.value = true;
      if (form.value.useStore) {
        form.value.useStoreDesc = form.value.useStore.map((item) => {
          return storeOptions.value.filter((obj) => obj.storeId === item)[0].storeName;
        });
        form.value.useStore = form.value.useStore.toString();
        form.value.useStoreDesc = form.value.useStoreDesc.toString();
      }
      form.value.useServiceId = form.value.useService.join(",");
      let a = form.value.useService.map((item) => {
        return serviceProjectList.value.filter((obj) => obj.id === item)[0].serviceName;
      });
      form.value.useServiceDesc = a.join(",");
      if (form.value.validType == 0) {
        form.value.startTime = form.value.dateRange[0];
        form.value.endTime = form.value.dateRange[1];
      }
      if (form.value.id != null) {
        updateDiscountsStrategy(form.value).then(response => {
          proxy.$modal.msgSuccess("修改成功");
          cancel();
        }).finally(() => {
          buttonLoading.value = false;
        });
      } else {
        addDiscountsStrategy(form.value).then(response => {
          proxy.$modal.msgSuccess("新增成功");
          cancel();
        }).finally(() => {
          buttonLoading.value = false;
        });
      }
    }
  });
}

function cancel() {
  changeModelValue(false)
  emit("refresh");
  emit("refList");
}

getServiceProjectList();
getStore();
</script>

<template>
  <!-- 添加或修改优惠策略对话框 -->
  <el-dialog :title="title" :modelValue="modelValue" @update:model-value="changeModelValue" width="880px" append-to-body>
    <el-form ref="discountsStrategyRef" :model="form" :rules="rules" label-width="150px">
      <el-form-item label="优惠券名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入优惠券名称" />
      </el-form-item>
      <el-form-item label="优惠券类型" prop="discountsType">
        <el-radio-group v-model="form.discountsType">
          <el-radio label="1">满减券</el-radio>
          <el-radio label="2">折扣券</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="减免金额" prop="discountsPrice" v-if="form.discountsType == 1">
        <el-input-number controls-position="right" v-model="form.discountsPrice" :precision="2" :step="1" :min="0.01"
                         placeholder="请输入减免金额" style="width: 50%" />
      </el-form-item>
      <el-form-item label="折扣" prop="discount" v-if="form.discountsType == 2">
        <div style="width: 100%">
          <el-input-number controls-position="right" v-model="form.discount" :precision="1" :step="1" :min="0.1" :max="10"
                           placeholder="请输入折扣" style="width: 50%" />
          <div class="tip">
            折扣范围0.1-10，9.5表示95折
          </div>
        </div>
      </el-form-item>
      <el-form-item label="最低消费金额" prop="reachPrice">
        <el-input-number controls-position="right" v-model="form.reachPrice" :precision="2" :step="1" :min="0.00"
                         placeholder="请输入最低消费金额" style="width: 50%" />
      </el-form-item>
      <el-form-item label="到期类型" prop="validType">
        <el-radio-group v-model="form.validType">
          <el-radio :label="1">领取后生效</el-radio>
          <el-radio :label="0">固定时间</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="有效天数" prop="effectiveDays" v-if="form.validType == 1">
        <el-input v-model="form.effectiveDays" placeholder="请输入有效天数" />
      </el-form-item>
      <el-form-item label="有效时间" prop="dateRange" v-if="form.validType == 0">
        <el-date-picker clearable
                        v-model="form.dateRange"
                        type="daterange"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 100%">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="适用服务项目" prop="useService">
        <el-select
          multiple
          clearable
          v-model="form.useService" filterable placeholder="请选择适用服务项目" style="width: 100%">
          <el-option v-for="(item,index) in serviceProjectList"
                     :key="index"
                     :value="item.id"
                     :label="item.serviceName">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可用门店" prop="useStore">
        <el-select
          v-model="form.useStore"
          placeholder="请选择可用门店"
          clearable
          multiple
          style="width: 100%">
          <el-option
            v-for="dict in storeOptions"
            :key="dict.storeId"
            :label="dict.storeName"
            :value="dict.storeId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可用次数" prop="degree">
        <el-input-number controls-position="right" v-model="form.degree" :step="1" :min="1"
                         placeholder="请输入可用次数" style="width: 50%" />
      </el-form-item>
      <el-form-item label="是否仅首次消费使用" prop="isFirst">
        <div>
          <el-radio-group v-model="form.isFirst">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <div class="tip">
            仅首次消费使用是指用户第一次下单时，才能使用该优惠券，对于之前已经有订单的用户，之后领取的此类优惠券则不可用
          </div>
        </div>
      </el-form-item>
      <el-form-item label="是否显示在领券中心" prop="isVisible">
        <div>
          <el-radio-group v-model="form.isVisible">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <div class="tip">领券中心是指娇娇儿小程序首页上的领券中心</div>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio-group v-model="form.state">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="使用说明">
        <el-input type="textarea" v-model="form.remark" :rows="6" show-word-limit :maxlength="1000"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button :loading="buttonLoading" type="primary" @click="submitForm()">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped>

</style>
