<template>
  <!-- 添加或修改权益卡对话框 -->
  <el-dialog :title="title" :modelValue="modelValue" @update:model-value="changeModelValue" width="800px" append-to-body>
    <el-form ref="businessEquityCardRef" :model="form" :rules="rules" label-width="140px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title" placeholder="请输入标题" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio-button label="1">正常</el-radio-button>
              <el-radio-button label="0">停用</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="权益价" prop="faceValue">
            <el-input-number v-model="form.faceValue" placeholder="请输入权益价" style="width: 100%" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="售价" prop="salePrice">
            <el-input-number v-model="form.salePrice" placeholder="请输入售价" style="width: 100%" />
          </el-form-item>
        </el-col>
      </el-row>
      <!--        <el-form-item label="服务项目" prop="unionId" :labelWidth="100">-->
      <!--          <el-input v-model="form.unionId" placeholder="请输入关联数据id" />-->
      <!--        </el-form-item>-->

      <!--        <el-form-item label="有效开始时间" :labelWidth="100" prop="effectiveBeginTime">-->
      <!--          <el-date-picker clearable style="width: 100%"-->
      <!--            v-model="form.effectiveBeginTime"-->
      <!--            type="datetime"-->
      <!--            value-format="YYYY-MM-DD HH:mm:ss"-->
      <!--            placeholder="请选择有效开始时间">-->
      <!--          </el-date-picker>-->
      <!--        </el-form-item>-->
      <!--        <el-form-item label="有效结束时间" prop="effectiveEndTime" :labelWidth="100">-->
      <!--          <el-date-picker clearable style="width: 100%"-->
      <!--            v-model="form.effectiveEndTime"-->
      <!--            type="datetime"-->
      <!--            value-format="YYYY-MM-DD HH:mm:ss"-->
      <!--            placeholder="请选择有效结束时间">-->
      <!--          </el-date-picker>-->
      <!--        </el-form-item>-->

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="有效天数" prop="effectiveDays">
            <el-input-number v-model="form.effectiveDays" placeholder="请输入有效天数" style="width: 100%" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="可购买次数" prop="purchaseTimes">
            <el-input-number v-model="form.purchaseTimes" placeholder="请输入可购买次数" style="width: 100%" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="可用门店" prop="useStore" style="width: 100%">
            <el-select
              v-model="form.useStore"
              placeholder="请选择可用门店"
              multiple
              style="width: 100%">
              <el-option
                v-for="dict in storeOptions"
                :key="dict.storeId"
                :label="dict.storeName"
                :value="dict.storeId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="服务项目" prop="unionId">
            <el-select v-model="form.unionId" multiple filterable placeholder="请选择服务项目" style="width: 100%">
              <el-option v-for="(item,index) in serviceProjectList"
                         :key="index"
                         :value="item.id"
                         :label="item.serviceName">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="是否赠送拓客账号" prop="giftExpand">
              <el-select
                v-model="form.giftExpand"
                placeholder="请选择"
                >
                <el-option
                  v-for="dict in sys_yes_no"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                ></el-option>
              </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="form.giftExpand == 'Y'" label="拓客角色" prop="expandRole">
            <el-select
              v-model="form.expandRole"
              placeholder="请选择"
            >
              <el-option
                v-for="dict in expandRoleOptions"
                :key="dict.id"
                :label="dict.name"
                :value="dict.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="使用说明" prop="description">
            <el-input type="textarea" v-model="form.description" rows="4" show-word-limit maxlength="200"
                      placeholder="请输入描述" />
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button :loading="buttonLoading" type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { listBase as storeListBase } from "@/api/store/base";
import { listServiceProject } from "@/api/business/serviceProject";
import { addBusinessEquityCard, updateBusinessEquityCard } from "@/api/business/businessEquityCard";
import {listRole} from "@/api/expand/role";

const { proxy } = getCurrentInstance();
const { sys_yes_no, equity_card_gift } = proxy.useDict("sys_yes_no", "equity_card_gift");
const emit = defineEmits(["refresh", "refList", "update:modelValue"]);
const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  form: {
    type: Object,
    default: {}
  },
  rules: {
    type: Object,
    default: {}
  }
});
const title = computed(() => {
    return props.title;
  }
);
const form = computed(() => {
    console.log(props.form);
    return props.form;
  }
);
const rules = computed(() => {
    return props.rules;
  }
);
const serviceProjectList = ref([]);
const storeOptions = ref([]);
const expandRoleOptions = ref([]);
const buttonLoading = ref(false);

function getStore() {
  storeListBase().then((response) => {
    storeOptions.value = response.rows;
    storeOptions.value.unshift({ storeId: "0", storeName: "不限门店" });
  });
}

function getServiceProjectList() {
  listServiceProject({ status: 1 }).then(res => {
    serviceProjectList.value = res.rows;
  });
}

function submitForm() {
  console.log("form", form.value)
  proxy.$refs["businessEquityCardRef"].validate(valid => {
    if (valid) {
      buttonLoading.value = true;
      let arr = form.value.unionId;
      console.log(arr.join(","));
      form.value.unionId = arr.join(",");
      form.value.useStoreDesc = form.value.useStore.map((item) => {
        return storeOptions.value.filter((obj) => obj.storeId === item)[0].storeName;
      });
      form.value.useStore = form.value.useStore.toString();
      form.value.useStoreDesc = form.value.useStoreDesc.toString();
      if (form.value.id != null) {
        updateBusinessEquityCard(form.value).then(response => {
          proxy.$modal.msgSuccess("修改成功");
          cancel();
        }).finally(() => {
          buttonLoading.value = false;
        });
      } else {
        addBusinessEquityCard(form.value).then(response => {
          proxy.$modal.msgSuccess("新增成功");
          cancel();
        }).finally(() => {
          buttonLoading.value = false;
        });
      }
    }
  });
}

function getExpandRole() {
  listRole({isUse:1}).then(response => {
    expandRoleOptions.value = response.rows;
  });
}

getServiceProjectList();
getStore();
getExpandRole();

function changeModelValue(value) {
  emit("update:modelValue", value);
}

function cancel() {
  changeModelValue(false);
  emit("refresh");
  emit("refList");
}
</script>

<style scoped lang="scss">
.tip {
  color: #999999;
  font-size: 12px;
}
</style>
